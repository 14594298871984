import { ActionProps } from "../store";

export const CHANGE_CITY = "city.change";
export const MESSAGES_LOADED = "messages.loaded";
export const MESSAGE_REPLIED = "message.replied";
export const NEW_MESSAGE = "message.new";

export function changeCity(newCity: string): ActionProps {
  return {
    type: CHANGE_CITY,
    payload: { name: newCity },
  };
}
