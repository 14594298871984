import BikeIcon from "./Bike";
import UserIcon from "../User";
import CarIcon from "./Car";
import ActivityIcon from "../ActivityIcon";

const modules = {
  BikeIcon,
  UserIcon,
  CarIcon,
  ActivityIcon,
};

export default modules;
