import { InternationalizationProvider } from "./internationalization";
import languages from "./internationalization/languages";
import { useRoutes } from "react-router-dom";
import useSettings from "./hooks/useSettings";
import { createCustomTheme } from "./theme";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import routes from "./routes";
import { StoreProvider } from "./store";
import { Amplify } from "aws-amplify";
import awsConfig from "./awsConfig";
import { reducer } from "./reducers";
import SettingsProvider from "./contexts/settingsContext";

Amplify.configure(awsConfig);

let initialState = {
  city: null,
  user: null,
  auth: {
    isLoading: true,
  },
  entities: {
    messages: [],
    messageKeys: {},
    deliveries: [],
    pendingDeliveries: [],
  },
};

function App() {
  const content: any = useRoutes(routes());
  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <StyledEngineProvider injectFirst>
      <SettingsProvider>
        <InternationalizationProvider translationMap={languages}>
          <ThemeProvider theme={theme}>
            <StoreProvider storeReducer={reducer} initialState={initialState}>
              <CssBaseline />
              {content}
            </StoreProvider>
          </ThemeProvider>
        </InternationalizationProvider>
      </SettingsProvider>
    </StyledEngineProvider>
  );
}

export default App;
