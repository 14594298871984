import useAuthenticationv2 from "../authentication/hooks/useAuthenticationv2";
import { CenteredContainer } from "./Centered";

type GuardProps = {
  children: JSX.Element | JSX.Element[];
  allowRoles?: string[];
};

export function CustomersOnlyGuard({ children, allowRoles }: GuardProps) {
  const { user, loading, hasRole } = useAuthenticationv2();

  if (loading) {
    return <CenteredContainer>Loading...</CenteredContainer>;
  }

  const userIsCustomer = user && user.attributes["custom:accountId"];

  if (userIsCustomer && allowRoles?.some(hasRole)) {
    return <>{children}</>;
  }

  return (
    <CenteredContainer>
      Unauthorized <a href="/">Click here to continue</a>
    </CenteredContainer>
  );
}

export function AnyUserWithPermission({ children, allowRoles }: GuardProps) {
  const { loading, hasRole } = useAuthenticationv2();

  if (loading) {
    return <CenteredContainer>Loading...</CenteredContainer>;
  }

  if (allowRoles?.some(hasRole)) {
    return <>{children}</>;
  }

  return (
    <CenteredContainer>
      Unauthorized <a href="/">Click here to continue</a>
    </CenteredContainer>
  );
}

export function EmployeesOnlyGuard({ children, allowRoles }: GuardProps) {
  const { user, loading, hasRole } = useAuthenticationv2();

  if (loading) {
    return <CenteredContainer>Loading...</CenteredContainer>;
  }

  // IMPORTANT: Customer user account should not be able to access this page
  const userIsCustomer = user && user.attributes["custom:accountId"];
  if (!userIsCustomer && allowRoles?.some(hasRole)) {
    return <>{children}</>;
  }

  return (
    <CenteredContainer>
      Unauthorized <a href="/">Click here to continue</a>
    </CenteredContainer>
  );
}
