import { CHANGE_CITY } from "../actions";
import { AuthLoadingStatus, SIGN_IN } from "../authentication/actions";
import { ActionProps, StoreProps } from "../store";
export function reducer(state: StoreProps, action: ActionProps) {
  switch (action.type) {
    // Authentication
    case SIGN_IN.success:
      return {
        ...state,
        user: { ...action.payload },
      };

    case SIGN_IN.failure:
      return {
        ...state,
        user: null,
      };

    case SIGN_IN.logout:
      return {
        ...state,
        user: null,
      };

    case SIGN_IN.addRoles:
      return {
        ...state,
        user: { ...state.user, roles: action.payload },
      };

    case AuthLoadingStatus:
      return {
        ...state,
        auth: {
          isLoading: action.payload,
        },
      };

    // City
    case CHANGE_CITY:
      return {
        ...state,
        city: action.payload,
      };
    default:
      return state;
  }
}
