import { createTheme, responsiveFontSizes } from "@mui/material";
import merge from "lodash/merge";
import { THEMES } from "../constants";
import components from "./components";
import { shadows } from "./shadows";
import themesOptions from "./themeOptions";
const baseOptions = {
  direction: "ltr",
  typography: {
    fontFamily: "'Montserrat', sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
};
export const createCustomTheme: any = (settings: {
  theme: string | number;
  direction: any;
  responsiveFontSizes: any;
}) => {
  /**
   * settings.theme value is 'light' or 'dark'
   * update settings in contexts/settingsContext.tsx
   */
  let themeOptions = themesOptions[settings.theme];

  if (!themeOptions) {
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  const mergedThemeOptions: any = merge({}, baseOptions, themeOptions, {
    direction: settings.direction,
  });
  let theme: any = createTheme(mergedThemeOptions); // set shadows

  theme.shadows = shadows(theme); // set components

  theme.components = components(theme);

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

// import { createTheme } from "@mui/material";

// const theme = createTheme({
//   palette: {
//     primary: {
//       light: "#757ce8",
//       main: "#3f50b5",
//       dark: "#002884",
//       contrastText: "#fff",
//     },
//     secondary: {
//       light: "#ff7961",
//       main: "#f44336",
//       dark: "#ba000d",
//     },
//   },
//   components: {
//     MuiTextField: {
//       defaultProps: {
//         variant: "outlined",
//         margin: "dense",
//         size: "small",
//         style: {
//           backgroundColor: "#fff",
//         },
//         fullWidth: true,
//       },
//     },
//     MuiButton: {
//       defaultProps: {
//         variant: "contained",
//         fullWidth: true,
//       },
//     },
//   },
// });
// export default theme;
