import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";

export const CenteredContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
}));
