export const roles = {
  superUser: "SuperUser",
  businessOwner: "BusinessOwner",
  manageCustomer: "ManageCustomer",
  clientManageRiders: "Client-ManageRiders",
  LocationEnrichment: "LocationEnrichment",
};

export const pageRoles = {
  AcessDashboard: [roles.businessOwner],
  ManagePickups: [roles.businessOwner],
  ManageBikers: [roles.businessOwner, roles.clientManageRiders],
  ManageCustomers: [roles.superUser, roles.manageCustomer],
  ManageVehicles: [roles.businessOwner, roles.clientManageRiders],
  ManagePlaces: [roles.superUser, roles.LocationEnrichment],
};
