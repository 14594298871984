import { createContext, useEffect, useState } from "react";

type InternationalizeProps = {
  children: JSX.Element | JSX.Element[];
  translationMap: object;
};

const altLanguageMapping: any = {
  "en-us": "en",
  "fr-fr": "fr",
};

const defaultLanguageState = ["en", (v: string) => {}, {}];
export const LanguageContext = createContext(defaultLanguageState);
export function InternationalizationProvider({
  children,
  translationMap = {},
}: InternationalizeProps) {
  const [language, setLanguage] = useState<string>("en");

  useEffect(() => {
    const browserLang = navigator.language.toLowerCase();
    const languageCode =
      browserLang in altLanguageMapping
        ? altLanguageMapping[browserLang]
        : browserLang;

    setLanguage(languageCode);
  }, []);

  return (
    <LanguageContext.Provider value={[language, setLanguage, translationMap]}>
      {children}
    </LanguageContext.Provider>
  );
}
