import { Auth } from "aws-amplify";
import awsConfig from "../awsConfig";
import {
  BikerProfile,
  CustomerProfile,
  Fare,
  PickupRequest,
  Vehicle,
} from "../typing/profile";
import useHttp from "@fast-ninjas/hooks.use-http";
import { useCallback } from "react";

type ForkinApiProps = {
  apiEndpoint?: string;
};

export default function useForkinAPI(props?: ForkinApiProps) {
  const BASE_URL = props?.apiEndpoint || awsConfig.apiEndpoint;
  const { post, get, put, patch, loading } = useHttp();

  const createAccessHeader = useCallback(async () => {
    const {
      signInUserSession: { accessToken },
    } = await Auth.currentAuthenticatedUser();
    return {
      Authorization: `Bearer ${accessToken?.jwtToken}`,
    };
  }, []);

  //============================Bikers endpoints============================
  const getBikerProfiles = useCallback(async (): Promise<BikerProfile[]> => {
    const authHeader = await createAccessHeader();
    try {
      const result = await get(`${BASE_URL}/api/delivery/bikers`, authHeader);
      return result;
    } catch (err) {
      console.log("Unable to load bikers", err);
    }
    return [];
  }, [get, createAccessHeader, BASE_URL]);

  const createBiker = useCallback(
    async (data: any) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await post(
          `${BASE_URL}/api/delivery/bikers`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [post, createAccessHeader, BASE_URL]
  );

  const updateBiker = useCallback(
    async (data: any, id: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await put(
          `${BASE_URL}/api/delivery/bikers/${id}`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [put, createAccessHeader, BASE_URL]
  );

  //============================Customers endpoints============================

  const getCustomerProfile = useCallback(async (): Promise<CustomerProfile> => {
    const authHeader = await createAccessHeader();
    try {
      const result = await get(
        `${BASE_URL}/api/customer/private/profile`,
        authHeader
      );
      return result;
    } catch (err) {
      console.log("Unable to load customer profile", err);
      return {} as CustomerProfile;
    }
  }, [get, createAccessHeader, BASE_URL]);

  const getCustomerProfiles = useCallback(
    async (country: string): Promise<CustomerProfile[]> => {
      const authHeader = await createAccessHeader();
      try {
        const result = await get(
          `${BASE_URL}/api/customers/${country}`,
          authHeader
        );
        return result;
      } catch (err) {
        console.log("Unable to load customers", err);
      }
      return [];
    },
    [get, createAccessHeader, BASE_URL]
  );

  const createCustomer = useCallback(
    async (data: any) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await post(
          `${BASE_URL}/api/customers/${data.country}`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [post, createAccessHeader, BASE_URL]
  );

  const updateCustomer = useCallback(
    async (data: any, id: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await put(
          `${BASE_URL}/api/customers/${data.country}/${id}`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [put, createAccessHeader, BASE_URL]
  );

  const resetPassword = useCallback(
    async (username: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await post(
          `${BASE_URL}/api/customers/reset-password/${username}`,
          {},
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
    },
    [post, createAccessHeader, BASE_URL]
  );

  //============================Get Image endpoints============================
  const getImage = useCallback(
    async (fileName: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await get(
          `${BASE_URL}/api/uploads?fileName=${fileName}`,
          authHeader
        );
        return result;
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [get, createAccessHeader, BASE_URL]
  );

  //============================Bikers endpoints============================
  const getVehicles = useCallback(async (): Promise<Vehicle[]> => {
    const authHeader = await createAccessHeader();
    try {
      const result = await get(`${BASE_URL}/api/vehicles`, authHeader);
      return result;
    } catch (err) {
      console.log("Unable to load vehicles", err);
    }
    return [];
  }, [get, createAccessHeader, BASE_URL]);

  const createVehicle = useCallback(
    async (data: any) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await post(`${BASE_URL}/api/vehicles`, data, authHeader);
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [post, createAccessHeader, BASE_URL]
  );

  const updateVehicle = useCallback(
    async (data: any, id: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await put(
          `${BASE_URL}/api/vehicles/${id}`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [put, createAccessHeader, BASE_URL]
  );

  //============================Places endpoints============================
  const getPlaces = useCallback(
    async (country: string, city: string): Promise<any[]> => {
      const authHeader = await createAccessHeader();
      try {
        const result = await get(
          `${BASE_URL}/api/${country}/${city}/places`,
          authHeader
        );
        return result;
      } catch (err) {
        console.log("Unable to load vehicles", err);
      }
      return [];
    },
    [get, createAccessHeader, BASE_URL]
  );

  const createPlace = useCallback(
    async (data: any, country: string, city: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await post(
          `${BASE_URL}/api/${country}/${city}/places`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [post, createAccessHeader, BASE_URL]
  );
  const updatePlace = useCallback(
    async (data: any, country: string, city: string, placeId: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await patch(
          `${BASE_URL}/api/${country}/${city}/places/${placeId}`,
          data,
          authHeader
        );
        if (result.status !== 200) throw new Error("Unable to update place");
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [patch, createAccessHeader, BASE_URL]
  );

  //============================Bikers endpoints============================
  const getPickups = useCallback(
    async (
      country: string,
      city: string,
      fromDate: number,
      toDate: number
    ): Promise<any[]> => {
      const authHeader = await createAccessHeader();
      try {
        const result = await get(
          `${BASE_URL}/api/pickups/${country}/${city}?fromDate=${fromDate}&toDate=${toDate}`,
          authHeader
        );
        return result;
      } catch (err) {
        console.log("Unable to load pickups", err);
      }
      return [];
    },
    [get, createAccessHeader, BASE_URL]
  );

  const sendUserAction = useCallback(
    async (data: { action: string; payload: any }): Promise<any> => {
      const authHeader = await createAccessHeader();
      try {
        const result = await post(
          `${BASE_URL}/api/customer/actions`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("Unable to update user action", err);
      }
    },
    [post, createAccessHeader, BASE_URL]
  );
  //============================Dashboard endpoints============================

  const getInsights = useCallback(async (): Promise<any> => {
    const authHeader = await createAccessHeader();
    try {
      const result = await get(
        `${BASE_URL}/api/dashboard/insights`,
        authHeader
      );
      return result;
    } catch (err) {
      console.log("Unable to load insights", err);
    }
    return [];
  }, [get, createAccessHeader, BASE_URL]);

  const calculateFare = useCallback(
    async (account: CustomerProfile, req: PickupRequest) => {
      const url = `${awsConfig.apiEndpoint}/api/pickups/${account.country}/${account.city}/${account.id}/fare`;
      const response = await post(url, req);
      if (!response.ok) {
        throw new Error("Error calculating fare");
      }
      return (await response.json()) as Fare;
    },
    [post]
  );

  return {
    getBikerProfiles,
    getCustomerProfiles,
    getImage,
    createCustomer,
    updateCustomer,
    resetPassword,
    createBiker,
    updateBiker,
    getVehicles,
    createVehicle,
    updateVehicle,
    createPlace,
    updatePlace,
    getPlaces,
    getPickups,
    sendUserAction,
    getInsights,
    getCustomerProfile,
    calculateFare,
    loading,
  };
}
