import LoadingScreen from "./components/LoadingScreen";
import {
  AnyUserWithPermission,
  CustomersOnlyGuard,
  EmployeesOnlyGuard,
} from "./components/guards";
import DashboardLayoutV1 from "./layouts/layout-v1/DashboardLayout";
import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import { pageRoles, roles } from "./roles";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const BikersList = Loadable(lazy(() => import("./pages/bikers")));
const CustomersList = Loadable(lazy(() => import("./pages/customers")));
const VehiclesList = Loadable(lazy(() => import("./pages/vehicles")));
const BikerDetails = Loadable(lazy(() => import("./pages/bikers/details")));
const CustomersDetails = Loadable(
  lazy(() => import("./pages/customers/details"))
);
const VehiclesDetails = Loadable(
  lazy(() => import("./pages/vehicles/details"))
);
const Dashboard = Loadable(lazy(() => import("./pages/dashboard/index")));
const Locator = Loadable(lazy(() => import("./pages/locator/index")));
const AddBiker = Loadable(lazy(() => import("./pages/bikers/add")));
const AddCustomer = Loadable(lazy(() => import("./pages/customers/add")));
const AddVehicle = Loadable(lazy(() => import("./pages/vehicles/add")));
const Error = Loadable(lazy(() => import("./pages/404")));
const Login = Loadable(lazy(() => import("./pages/userAccount/Login")));
const ForgotPassword = Loadable(
  lazy(() => import("./pages/userAccount/ForgotPassword"))
);
const SetPasswordSuccess = Loadable(
  lazy(() => import("./pages/success/SetPasswordSuccess"))
);
// const SignUpSuccess = Loadable(
//   lazy(() => import("./pages/success/SignupSuccess"))
// );
const AddCustomerSuccess = Loadable(
  lazy(() => import("./pages/success/AddCustomerSuccess"))
);
const AddVehicleSuccess = Loadable(
  lazy(() => import("./pages/success/AddVehicleSuccess"))
);
const AddBikerSuccess = Loadable(
  lazy(() => import("./pages/success/AddBikerSuccess"))
);
const PasswordResetSuccess = Loadable(
  lazy(() => import("./pages/success/PasswordResetSuccess"))
);
// const VerifyOtp = Loadable(lazy(() => import("./pages/userAccount/verifyOtp")));
const Pickups = Loadable(lazy(() => import("./pages/pickups")));
const PickupDetails = Loadable(lazy(() => import("./pages/pickups/details")));
const SetupPage = Loadable(lazy(() => import("./pages/setup/index")));

const ActiveLayout = () => {
  return <DashboardLayoutV1 />;
};

const routes: any = () => {
  return [
    ...authRoutes,
    {
      path: "/bikers",
      element: <ActiveLayout />,
      children: bikersRoutes,
    },
    {
      path: "/pickups",
      element: <ActiveLayout />,
      children: pickupsRoutes,
    },
    {
      path: "/customers",
      element: <ActiveLayout />,
      children: customersRoutes,
    },
    {
      path: "/vehicles",
      element: <ActiveLayout />,
      children: vehiclesRoutes,
    },
    {
      path: "/dashboard",
      element: <ActiveLayout />,
      children: dashboardRoutes,
    },
    {
      path: "/locator",
      element: <ActiveLayout />,
      children: locatorRoutes,
    },
    {
      path: "/customers/success",
      element: <AddCustomerSuccess />,
      children: customerSuccessRoutes,
    },
    {
      path: "/vehicles/success",
      element: <AddVehicleSuccess />,
      children: vehicleSuccessRoutes,
    },
    {
      path: "/bikers/success",
      element: <AddBikerSuccess />,
      children: bikerSuccessRoutes,
    },
    {
      path: "/setup",
      element: <ActiveLayout />,
      children: [
        {
          path: "/setup",
          element: (
            <CustomersOnlyGuard allowRoles={[roles.businessOwner]}>
              <SetupPage />
            </CustomersOnlyGuard>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <Error />,
    },
  ];
};

const authRoutes = [
  {
    path: "/",
    element: <Navigate to="/login" />,
  },
  {
    path: "forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "setPassword/success",
    element: <SetPasswordSuccess />,
  },
  {
    path: "login",
    element: <Login />,
  },
  // {
  //   path: "signup/success/",
  //   element: <SignUpSuccess />,
  // },
  {
    path: "forgotPassword/success/",
    element: <PasswordResetSuccess />,
  },
  // {
  //   path: "verify-otp",
  //   element: <VerifyOtp />,
  // },
];
const bikersRoutes = [
  {
    path: "/bikers/",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageBikers}>
        <BikersList roles={["BusinessOwner"]} />
      </CustomersOnlyGuard>
    ),
  },
  {
    path: "/bikers/details",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageBikers}>
        <BikerDetails roles={["BusinessOwner"]} />
      </CustomersOnlyGuard>
    ),
  },
  {
    path: "/bikers/add",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageBikers}>
        <AddBiker roles={["BusinessOwner"]} />
      </CustomersOnlyGuard>
    ),
  },
  {
    path: "/bikers/update",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageBikers}>
        <AddBiker roles={["BusinessOwner"]} />
      </CustomersOnlyGuard>
    ),
  },
];
const customersRoutes = [
  {
    path: "/customers/",
    element: (
      <EmployeesOnlyGuard allowRoles={pageRoles.ManageCustomers}>
        <CustomersList />
      </EmployeesOnlyGuard>
    ),
  },
  {
    path: "/customers/details",
    element: (
      <EmployeesOnlyGuard allowRoles={pageRoles.ManageCustomers}>
        <CustomersDetails />
      </EmployeesOnlyGuard>
    ),
  },
  {
    path: "/customers/add",
    element: (
      <EmployeesOnlyGuard allowRoles={pageRoles.ManageCustomers}>
        <AddCustomer />
      </EmployeesOnlyGuard>
    ),
  },
  {
    path: "/customers/add/success",
    element: (
      <EmployeesOnlyGuard allowRoles={pageRoles.ManageCustomers}>
        <AddCustomerSuccess />
      </EmployeesOnlyGuard>
    ),
  },
  {
    path: "/customers/update",
    element: (
      <EmployeesOnlyGuard allowRoles={pageRoles.ManageCustomers}>
        <AddCustomer />
      </EmployeesOnlyGuard>
    ),
  },
];
const vehiclesRoutes = [
  {
    path: "/vehicles/",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageVehicles}>
        <VehiclesList />
      </CustomersOnlyGuard>
    ),
  },
  {
    path: "/vehicles/details",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageVehicles}>
        <VehiclesDetails />
      </CustomersOnlyGuard>
    ),
  },
  {
    path: "/vehicles/add",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageVehicles}>
        <AddVehicle />
      </CustomersOnlyGuard>
    ),
  },
  {
    path: "/vehicles/add/success",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageVehicles}>
        <AddVehicleSuccess />
      </CustomersOnlyGuard>
    ),
  },
  {
    path: "/vehicles/update",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageVehicles}>
        <AddVehicle />
      </CustomersOnlyGuard>
    ),
  },
];
const dashboardRoutes = [
  {
    path: "/dashboard",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.AcessDashboard}>
        <Dashboard />
      </CustomersOnlyGuard>
    ),
  },
];
const pickupsRoutes = [
  {
    path: "/pickups",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManagePickups}>
        <Pickups />
      </CustomersOnlyGuard>
    ),
  },
  {
    path: "/pickups/details",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManagePickups}>
        <PickupDetails />
      </CustomersOnlyGuard>
    ),
  },
];
const locatorRoutes = [
  {
    path: "/locator",
    element: (
      <AnyUserWithPermission allowRoles={pageRoles.ManagePlaces}>
        <Locator />
      </AnyUserWithPermission>
    ),
  },
];
const customerSuccessRoutes = [
  {
    path: "customers/success",
    element: (
      <EmployeesOnlyGuard allowRoles={pageRoles.ManageCustomers}>
        <AddCustomerSuccess />
      </EmployeesOnlyGuard>
    ),
  },
];
const bikerSuccessRoutes = [
  {
    path: "bikers/success",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageBikers}>
        <AddBikerSuccess />
      </CustomersOnlyGuard>
    ),
  },
];
const vehicleSuccessRoutes = [
  {
    path: "vehicles/success",
    element: (
      <CustomersOnlyGuard allowRoles={pageRoles.ManageVehicles}>
        <AddVehicleSuccess />
      </CustomersOnlyGuard>
    ),
  },
];
export default routes;
