import { Box, IconButton, MenuItem, Popover, styled } from "@mui/material";
import { H6 } from "../../../components/Typography";
import { useRef, useState } from "react";
import { useTranslation } from "../../../internationalization/useTranslation";

// TODO: We need a more scalable way to do this.
// What happens when we add a new language?
// Will we know this objects is hidden here???????
const languageOptions: any = {
  en: {
    icon: "/imgs/eng.png",
    label: "English",
    key: "en",
  },
  fr: {
    icon: "/imgs/french.png",
    label: "French",
    key: "fr",
  },
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const IconWrapper = styled(Box)(() => ({
  display: "flex",
  height: 24,
  width: 24,
  padding: "2px",
  "& img": {
    width: "100%",
    borderRadius: "50%",
    objectFit: "cover",
  },
}));
const ItemWrapper = styled(Box)(() => ({
  display: "flex",
  "& img": {
    width: "100%",
  },
}));

const LanguagePopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { language, setLanguage } = useTranslation();

  const defaultLang = languageOptions[language]?.label;

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleChangeLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
    setOpen(false);
  };

  return (
    <>
      <StyledIconButton onClick={handleOpen} ref={anchorRef}>
        <IconWrapper>
          <img
            alt={languageOptions[defaultLang]?.label}
            src={languageOptions[defaultLang]?.icon}
          />
        </IconWrapper>
      </StyledIconButton>
      <Popover
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        PaperProps={{
          sx: {
            width: 150,
            padding: "0.5rem 0",
          },
        }}
      >
        {Object.keys(languageOptions).map((lang) => (
          <MenuItem
            key={languageOptions[lang].label}
            onClick={() => handleChangeLanguage(languageOptions[lang].key)}
          >
            <ItemWrapper>
              <img
                alt={languageOptions[lang].label}
                src={languageOptions[lang].icon}
                width={30}
                height={30}
              />
              <H6 fontWeight={600} ml={1}>
                {languageOptions[lang].label}
              </H6>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
