import { SvgIcon } from "@mui/material";

const ActivityIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 21 20" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 5.01104V14.1759C0 16.9451 2.24176 19.2528 4.94505 19.1868H8.04394L8.10994 19.168V12.6594H5.67039V9.89016H8.04401V7.78028C8.04401 5.34072 9.49456 4.02204 11.6704 4.02204C12.7253 4.02204 13.8462 4.21984 13.8462 4.21984V6.59346H12.6594C11.4726 6.59346 11.077 7.31874 11.077 8.10995V9.89016H13.7803L13.3847 12.6594H11.1429V19.1869L14.1758 19.1868C16.945 19.1868 19.1868 16.9451 19.1868 14.1759V5.01104C19.1868 2.24182 16.945 6.10352e-05 14.1758 6.10352e-05H5.01098C2.24176 6.10352e-05 0 2.24182 0 5.01104Z"
      />
    </SvgIcon>
  );
};

export default ActivityIcon;
