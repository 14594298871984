import {
  AppBar,
  Box,
  Button,
  styled,
  Theme,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import LanguagePopover from "./popovers/LanguagePopover";
import ProfilePopover from "./popovers/ProfilePopover";
import useForkinAPI from "../../hooks/useForkinApi";
import { roles } from "../../roles";
import useAuthenticationv2 from "../../authentication/hooks/useAuthenticationv2";

// ------------------------------------------------
// custom styled components
const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
}));
const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));

const ToggleIcon = styled(Box)(
  ({ theme, width }: { theme?: Theme; width?: number }) => ({
    height: 3,
    margin: "5px",
    marginLeft: 0,
    width: width || 25,
    borderRadius: "10px",
    transition: "width 0.3s",
    backgroundColor: theme?.palette.primary.main,
  })
);

const DashboardHeader = (props: {
  setShowMobileSideBar: any;
  setShowSideBar: any;
}) => {
  const { setShowMobileSideBar } = props;
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { user, hasRole } = useAuthenticationv2();
  const { getCustomerProfile } = useForkinAPI();
  const [customer, setCustomer] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (hasRole(roles.businessOwner)) {
      const getProfiles = async () => {
        const response = await getCustomerProfile();
        setCustomer(response);
      };
      getProfiles();
    }
  }, [user, getCustomerProfile, hasRole]);

  const createNewPickupRequest = () => {
    window.open(`${customer.appUrl}`, "_blank");
  };
  return (
    <>
      <DashboardHeaderRoot position="sticky" sx={{ paddingX: 1 }}>
        <StyledToolBar>
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={setShowMobileSideBar}
          >
            <ToggleIcon />
            <ToggleIcon width={18} />
            <ToggleIcon width={9} />
          </Box>

          <Box flexGrow={1} ml={1} />

          {hasRole(roles.businessOwner) && (
            <Button variant="outlined" onClick={createNewPickupRequest}>
              Create new request
            </Button>
          )}

          {upSm && (
            <Fragment>
              <LanguagePopover />
            </Fragment>
          )}
          <ProfilePopover />
        </StyledToolBar>
      </DashboardHeaderRoot>
    </>
  );
};

export default DashboardHeader;
