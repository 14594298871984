import { LanguageContext } from ".";
import { useCallback, useContext, useMemo } from "react";

export function useTranslation() {
  const [language, setLanguage, translationMap] = useContext(
    LanguageContext
  ) as any;

  const untypedObjectMap = translationMap as any;
  const languageLabels = useMemo(
    () =>
      language in untypedObjectMap
        ? untypedObjectMap[language]
        : untypedObjectMap["en"],
    [language, untypedObjectMap]
  );

  const supportedLanguages = useMemo(
    () => Object.keys(translationMap),
    [translationMap]
  );

  const translate = useCallback(
    (messageKey: string) => languageLabels[messageKey],
    [languageLabels]
  );

  return { translate, language, setLanguage, supportedLanguages };
}
