const configField = "awsConfig";
const context: any = window;
type AppConfig = {
  Auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
  };
  apiEndpoint: string;
  googleApiKey: string;
};

const awsConfig: AppConfig = context[configField];

export default awsConfig;
