import { Fragment, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import DashboardHeader from "../layout-parts/DashboardHeader";
import LayoutBodyWrapper from "../layout-parts/LayoutBodyWrapper";
import DashboardSidebar from "./DashboardSidebar";
import useAuthenticationv2 from "../../authentication/hooks/useAuthenticationv2";
import { CenteredContainer } from "../../components/Centered";

const DashboardLayout = ({ children }: { children?: any }) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);
  const navigate = useNavigate();
  const { user, signOut, loading } = useAuthenticationv2();

  useEffect(() => {
    if (!loading && !user) {
      signOut();
      navigate("/login");
    }
  }, [loading, user, signOut, navigate]);

  if (loading) {
    return <CenteredContainer>Loading...</CenteredContainer>;
  }

  return (
    <Fragment>
      <DashboardSidebar
        showSideBar={showSideBar}
        showMobileSideBar={showMobileSideBar}
        closeMobileSideBar={() => setShowMobileSideBar(false)}
      />

      <LayoutBodyWrapper>
        <DashboardHeader
          setShowSideBar={() => setShowSideBar((state) => !state)}
          setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
        />
        {children || <Outlet />}
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
