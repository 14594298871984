import { createContext, Dispatch, useContext, useReducer } from "react";
import { UserData } from "../authentication/types";
export type ActionProps = {
  type: string;
  payload: any;
};

type StoreProviderProps = {
  storeReducer: (prevState: any, action: ActionProps) => any;
  initialState: any;
  children?: JSX.Element | JSX.Element[] | undefined;
};

export type StoreProps = {
  user?: UserData;
  city?: { name: string };
  auth?: { isLoading: boolean };
  entities: {};
};

const StoreContext = createContext({} as [StoreProps, Dispatch<ActionProps>]);

export const useStore = () => useContext(StoreContext);

export const StoreProvider: any = ({
  storeReducer,
  initialState,
  children,
}: StoreProviderProps) => {
  const store = useReducer<
    (prevState: StoreProps, action: ActionProps) => StoreProps
  >(storeReducer, initialState);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
