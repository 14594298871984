/* eslint-disable array-callback-return */
import {
  Box,
  List,
  ListItemButton,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Scrollbar from "../../components/ScrollBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBox from "../../components/flexbox/FlexBox";
import { lightTheme } from "../../constants";
import LayoutDrawer from "../layout-parts/LayoutDrawer";
import AccordionMenu from "./AccordionMenu";
import { H6, Small } from "../../components/Typography";
import { useTranslation } from "../../internationalization/useTranslation";
import Icons from "../../icons/sidebar";
import Pin from "../../icons/Pin";
import MopedIcon from "@mui/icons-material/Moped";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { pageRoles, roles } from "../../roles";
import useAuthenticationv2 from "../../authentication/hooks/useAuthenticationv2";
import { Settings } from "@mui/icons-material";

const StyledLogo = styled(Box)(() => ({
  paddingLeft: 8,
  fontWeight: 700,
  fontSize: 20,
}));
const StyledListItemButton = styled(ListItemButton)(
  ({ active, theme }: { active: any; theme?: any }) => ({
    padding: 12,
    marginBottom: 8,
    color: active ? theme.palette.primary.main : "inherit",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiSvgIcon-root": {
      color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    },
  })
);
const Dot = styled(Box)(() => ({
  width: 5,
  height: 5,
  marginRight: 10,
  borderRadius: "50%",
}));
const SubMenuItem = styled(FlexBox)(({ theme, active }) => ({
  cursor: "pointer",
  overflow: "hidden",
  alignItems: "center",
  position: "relative",
  padding: "0.6rem 1rem",
  backgroundColor: active
    ? lightTheme(theme)
      ? theme.palette.grey[200]
      : theme.palette.divider
    : "transparent",
  "& div": {
    backgroundColor: active
      ? theme.palette.primary.main
      : theme.palette.grey[600],
  },
  "& small": {
    color: active ? theme.palette.primary.main : theme.palette.text.primary,
  },
  "&:hover": {
    backgroundColor: lightTheme(theme)
      ? theme.palette.grey[200]
      : theme.palette.divider,
    "& div": {
      backgroundColor: theme.palette.primary.main,
    },
    "& small": {
      color: theme.palette.primary.main,
    },
    "&::before": {
      opacity: 1,
    },
  },
  "&::before": {
    left: 0,
    width: 2,
    content: '""',
    height: "100%",
    borderRadius: 5,
    position: "absolute",
    opacity: active ? 1 : 0,
    transition: "opacity 0.3s ease",
    backgroundColor: theme.palette.primary.main,
  },
})); // root component

const DashboardSidebar = (props: any) => {
  const { translate } = useTranslation();
  const sideMenuList: any = [
    {
      title: translate("dashboardSideMenuLabel"),
      Icon: DashboardIcon,
      path: "/dashboard",
      allowedRoles: pageRoles.AcessDashboard,
    },
    {
      title: translate("pickupSideMenuLabel"),
      Icon: MopedIcon,
      path: "/pickups",
      allowedRoles: pageRoles.ManagePickups,
    },
    {
      title: translate("bikersSideMenuLabel"),
      Icon: Icons.BikeIcon,
      path: "/bikers",
      allowedRoles: pageRoles.ManageBikers,
    },
    {
      title: translate("customersSideMenuLabel"),
      Icon: Icons.UserIcon,
      path: "/customers",
      allowedRoles: pageRoles.ManageCustomers,
    },
    {
      title: translate("vehiclesSideMenuLabel"),
      Icon: Icons.CarIcon,
      path: "/vehicles",
      allowedRoles: pageRoles.ManageVehicles,
    },
    {
      title: translate("locatorSideMenuLabel"),
      Icon: Pin,
      path: "/locator",
      allowedRoles: pageRoles.ManagePlaces,
    },
    {
      title: translate("setupSideMenuLabel"),
      Icon: Settings,
      path: "/setup",
      allowedRoles: [roles.businessOwner],
    },
  ];
  const { showMobileSideBar, closeMobileSideBar } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState<boolean | string>(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200)); // active accordion

  const { hasRole } = useAuthenticationv2();

  const activeAccordion = () => {
    sideMenuList.forEach((list: any) => {
      if (list.children && list.children.length > 0) {
        const findItem = list.children.find(
          (item: any) => item.path === pathname
        );
        if (findItem) setExpanded(list.title);
      }
    });
  };

  const checkRoles = (rolesAllowed: string[]) => {
    return rolesAllowed.filter((role) => hasRole(role));
  };

  useEffect(
    activeAccordion,
    //eslint-disable-next-line  react-hooks/exhaustive-deps
    [pathname]
  );

  const handleAccordionChange = (panel: any) => (_: any, expand: any) => {
    setExpanded(expand ? panel : false);
  };

  const handleSubMenuItem = (path: any) => {
    if (path) {
      closeMobileSideBar();
      navigate(path);
    }
  };

  const activeRoute = (path: any) => (path === pathname ? 1 : 0); // common side bar content

  const sideBarContent = (
    <Scrollbar autoHide clickOnTrack={false}>
      <List
        sx={{
          height: "100%",
          padding: 2,
        }}
      >
        {/* LOGO */}
        <FlexBox ml={1.5} mb={2} justifyContent="space-between">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img src="/imgs/logo-sm.png" alt="logo" width={18} />
            <StyledLogo>DeliverIt</StyledLogo>
          </Box>
        </FlexBox>

        {sideMenuList.map((item: any, index: number) => {
          if (item.children && item.children.length > 0) {
            return (
              <AccordionMenu
                key={index}
                title={item.title}
                expandedItem={expanded}
                handleChange={handleAccordionChange}
                accordionHeader={
                  <FlexBox alignItems="center" gap={1}>
                    <item.Icon
                      sx={{
                        fontSize: 20,
                      }}
                    />
                    <H6 lineHeight={1}>{item.title}</H6>
                  </FlexBox>
                }
              >
                {item.children.map((menuItem: any, key: any) => {
                  if (!menuItem.subChildren) {
                    return (
                      <SubMenuItem
                        key={key}
                        active={activeRoute(menuItem.path)}
                        onClick={() => handleSubMenuItem(menuItem.path)}
                      >
                        <Dot />
                        <Small color="text.primary">{menuItem.name}</Small>
                      </SubMenuItem>
                    );
                  }
                })}
              </AccordionMenu>
            );
          }

          if (checkRoles(item.allowedRoles).length > 0) {
            return (
              <StyledListItemButton
                key={index}
                disableRipple
                active={item.path === pathname ? 1 : 0}
                onClick={() => {
                  setExpanded(false);
                  handleSubMenuItem(item.path);
                }}
              >
                <item.Icon
                  sx={{
                    color: "text.secondary",
                    fontSize: 20,
                  }}
                />
                <H6 ml={1} lineHeight={1}>
                  {item.title}
                </H6>
              </StyledListItemButton>
            );
          }
        })}
      </List>
    </Scrollbar>
  ); // for mobile device

  if (downMd) {
    return (
      <LayoutDrawer open={showMobileSideBar} onClose={closeMobileSideBar}>
        {sideBarContent}
      </LayoutDrawer>
    );
  }

  return (
    <LayoutDrawer open={showMobileSideBar} onClose={closeMobileSideBar}>
      {sideBarContent}
    </LayoutDrawer>
  );
};

export default DashboardSidebar;
