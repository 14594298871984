import {
  Badge,
  Box,
  ButtonBase,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";
import AppAvatar from "../../../components/avatars/AppAvatar";
import FlexBox from "../../../components/flexbox/FlexBox";
import { H6, Small, Tiny } from "../../../components/Typography";
import { Fragment, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PopoverLayout from "./PopoverLayout"; // styled components
import { useTranslation } from "../../../internationalization/useTranslation";
import useAuthenticationv2 from "../../../authentication/hooks/useAuthenticationv2";

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  padding: 5,
  marginLeft: 4,
  borderRadius: 30,
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  cursor: "pointer",
  padding: "5px 1rem",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ProfilePopover = () => {
  const { translate } = useTranslation();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { user, signOut } = useAuthenticationv2();
  const [open, setOpen] = useState(false);
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const handleLogout = async () => {
    await signOut();
    navigate("/login");
  };

  return (
    <Fragment>
      <StyledButtonBase
        disableRipple
        ref={anchorRef}
        onClick={() => setOpen(true)}
      >
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            alignItems: "center",
            "& .MuiBadge-badge": {
              width: 11,
              height: 11,
              right: "4%",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "success.main",
            },
          }}
        >
          {upSm && (
            <Small mx={1} color="text.secondary">
              <Small fontWeight="600" display="inline">
                {user?.attributes?.given_name}
              </Small>
            </Small>
          )}
          <AppAvatar
            src={"/static/avatar/001-man.svg"}
            sx={{
              width: 28,
              height: 28,
            }}
          />
        </Badge>
      </StyledButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center" gap={1}>
            <AppAvatar
              src={"/static/avatar/001-man.svg"}
              sx={{
                width: 35,
                height: 35,
              }}
            />

            <Box>
              <H6>{user?.attributes.given_name}</H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                {user?.attributes.given_name}
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
        <Box pt={1}>
          <StyledSmall onClick={handleLogout}>
            {translate("signOut")}
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
