import { ActionProps } from "../../store";
import { UserData } from "../types";

export const SIGN_IN = {
  success: "UserAuthenticated",
  failure: "AuthenticationFailed",
  started: "AuthenticationStarted",
  logout: "LogoutUser",
  addRoles: "AddRoles",
};

export const AuthLoadingStatus = "Auth.Loading.Status";

export function loginSuccess(user: UserData): ActionProps {
  return {
    type: SIGN_IN.success,
    payload: user,
  };
}

export function loginFailure(): ActionProps {
  return {
    type: SIGN_IN.failure,
    payload: {},
  };
}

export function setAuthenticationLoadingStatus(status: boolean): ActionProps {
  return {
    type: AuthLoadingStatus,
    payload: status,
  };
}

export function logoutUser(): ActionProps {
  return {
    type: SIGN_IN.logout,
    payload: {},
  };
}
export function addUserRoles(roles: Array<string>): ActionProps {
  return {
    type: SIGN_IN.addRoles,
    payload: roles,
  };
}
